import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import {
  View,
  StyleSheet,
  Pressable,
  TextInput as RnTextInput,
} from 'react-native'
import { Layout } from '../components/Layout'
import { Label } from 'src/design-system/components/Text'
import { useNavigation } from '@react-navigation/native'
import { DividerWithText } from '../components/DividerWithText'
import { EmailTextInput } from 'src/design-system/components/TextInput/EmailTextInput'
import { PasswordTextInput } from 'src/design-system/components/TextInput/PasswordTextInput'
import { Colors } from 'src/design-system/theme'
import { useTranslation } from 'react-i18next'
import {
  Auth,
  AuthAPI,
  AUTH_STATE,
  useAuth,
  userPinSessionService,
  userSessionService,
} from 'src/context/auth'
import { RuleStatus } from 'src/design-system/components/TextInput/TextInput'
import { InvalidStatus } from '../components/InvalidStatus'
import { PasswordAction } from 'src/context/auth/utils/type'
import { ErrorMessage } from '../components/ErrorMessage'
import { AuthPrimaryButton, AuthSecondaryButton } from '../components/Buttons'
import { AuthHeader } from '../components/Header'

export const LoginWithEmail: React.FC = () => {
  const navigation = useNavigation()
  const { t } = useTranslation()
  const { toggleAuthState } = useAuth()
  const errorCode = userSessionService.getUserSession()?.errorCode
  const [email, setEmail] = useState<string>('')
  const [isEmailDirty, setIsEmailDirty] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState<RuleStatus[] | null>(null)
  const [password, setPassword] = useState<string>('')
  const [isLocked, setIsLocked] = useState<boolean>(
    userSessionService.checkSignInLocked(),
  )
  const [isFormValid, setIsFormValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Needed so that we can focus on the password field after pressing enter on the email field
  const passwordRef = useRef<RnTextInput>(null)

  const handleExistingPin = () => {
    const allUserPinSession = userPinSessionService.getAllUserPinSession()
    if (!allUserPinSession || allUserPinSession.length === 0) {
      return false
    }
    const allUserPinSessionArray = Object.values(allUserPinSession)
    return allUserPinSessionArray.filter(pin => !!pin.pinId).length > 0
  }

  const handleResetPassword = () => {
    navigation.navigate(AUTH_STATE.RESET_PASSWORD.toLowerCase())
  }

  const emailInputOnBlur = () => {
    setIsEmailDirty(true)
    setIsLocked(userSessionService.checkSignInLocked(email))
  }
  const handleSignIn = async () => {
    if (errorCode) userSessionService.resetErrorCode()
    const furtherAction = await Auth.signIn(email, password)
    if ('errorCode' in furtherAction) {
      return setIsLocked(userSessionService.checkSignInLocked(email))
    }
    toggleAuthState(AUTH_STATE.LOGIN, true)
    let newAuthState = toggleAuthState(AUTH_STATE.RESET_PASSWORD, true)

    if (furtherAction) {
      if (
        furtherAction?.ChallengeName === PasswordAction.NEW_PASSWORD_REQUIRED
      ) {
        const session = furtherAction.Session ?? ''
        const userAttributes = JSON.parse(
          furtherAction?.ChallengeParameters?.userAttributes ?? '{}',
        )
        const email = userAttributes?.email || ''
        userSessionService.setPwdActionInfo({
          email,
          session,
          action: PasswordAction.NEW_PASSWORD_REQUIRED,
          isPasswordExpired: !!furtherAction?.isPasswordExpired,
        })
        return navigation.navigate(newAuthState.toLowerCase())
      }
    }
    const session = userSessionService.getUserSession()
    const isPasswordExpired =
      new Date() >
      new Date(session?.userAttributes?.password_expired_at || Date.now())
    const isPasswordSkipped =
      new Date() <
      new Date(session?.userAttributes?.skip_change_pwd_exp || Date.now())
    // if password is not expired or skipped
    if (!isPasswordExpired || isPasswordSkipped) {
      newAuthState = toggleAuthState(AUTH_STATE.CHANGE_PASSWORD, true)
    }
    const isEmailVerified = session?.userAttributes?.email_verified
    const isEmailSkipped =
      new Date() <
      new Date(session?.userAttributes?.skip_verifyemail_exp || Date.now())
    if (isEmailVerified || isEmailSkipped) {
      newAuthState = toggleAuthState(AUTH_STATE.VERIFY_EMAIL, true)
    }
    navigation.navigate(newAuthState.toLowerCase())
  }

  const handleSubmit = () => {
    if (isFormValid) {
      setIsLoading(true)
      handleSignIn().then(() => setIsLoading(false))
    }
  }

  const handleSignInWithPin = () => {
    navigation.navigate(AUTH_STATE.UNLOCK_WITH_PIN.toLowerCase())
  }

  useLayoutEffect(() => {
    if (errorCode) AuthAPI.postError({ errorCode })
    const session = userSessionService.getUserSession()
    if (
      session?.authenticationResult?.accessToken &&
      session?.userAttributes?.id
    ) {
      toggleAuthState(AUTH_STATE.LOGIN, true)
      const newState2 = toggleAuthState(AUTH_STATE.RESET_PASSWORD, true)
      if (newState2 === AUTH_STATE.AUTHENTICATED) return
      navigation.navigate(newState2.toLowerCase())
    }
  }, [errorCode, navigation, toggleAuthState])

  useEffect(() => {
    setIsFormValid(
      (isValidEmail?.every(email => email.isValid) && !!password) ?? false,
    )
  }, [isValidEmail, password])

  const LoginWithPin = () =>
    handleExistingPin() ? (
      <View style={styles.dividerContainer}>
        <DividerWithText text={t('login:label.alreadySignedIn')} />
        <AuthSecondaryButton
          style={{ marginBottom: 24 }}
          onPress={handleSignInWithPin}
          title={t('login:label.unlockWithPin')}
          ariaLabel={'UnlockWithPinBtn'}
        />
      </View>
    ) : null

  const ForgotPasswordButton = () => (
    <View style={styles.forgotPasswordContainer}>
      <Pressable onPress={handleResetPassword} aria-label="ForgotPasswordBtn">
        <Label size="M" style={styles.forgotPasswordBtn}>
          {t('login:label.forgotPassword')}
        </Label>
      </Pressable>
    </View>
  )

  return (
    <Layout>
      <AuthHeader title={t('login:label.signInWithEmail')} />
      <View style={styles.formFieldContainer}>
        <EmailTextInput
          onValidation={setIsValidEmail}
          onBlur={emailInputOnBlur}
          ariaLabel="EmailField"
          textChange={setEmail}
          autoComplete={'email'}
          returnKeyType="next"
          onSubmitEditing={() => passwordRef.current?.focus()}
        />
        <View style={styles.passwordFieldContainer}>
          <PasswordTextInput
            inputRef={passwordRef}
            onValidation={() => {}}
            onBlur={() => {}}
            textChange={setPassword}
            skipValidation={true}
            disabled={isLocked}
            ariaLabel="PasswordField"
            autoComplete="current-password"
            returnKeyType="go"
            onSubmitEditing={handleSubmit}
          />
          {isValidEmail?.map(email =>
            !email.isValid && isEmailDirty ? (
              <InvalidStatus key={email.message} status={email.message} />
            ) : null,
          )}
          {isLocked ? (
            <ErrorMessage message={t('login:error.lockedAccount')} />
          ) : null}
        </View>
      </View>
      <ForgotPasswordButton />
      <AuthPrimaryButton
        onPress={handleSubmit}
        disabled={isLocked || !isFormValid || isLoading}
        title={t('login:label.signIn')}
        ariaLabel="SignInBtn"
        loading={isLoading}
      />
      <LoginWithPin />
    </Layout>
  )
}

const styles = StyleSheet.create({
  formFieldContainer: { width: '100%', gap: 32, marginVertical: 16 },
  passwordFieldContainer: { gap: 8 },
  forgotPasswordContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
    marginBottom: 48,
  },
  forgotPasswordBtn: { color: Colors.Contents.accent },
  dividerContainer: { marginTop: 48, gap: 24 },
})
