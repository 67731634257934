import React, { useState } from 'react'
import {
  CustomTextInputProps,
  RuleStatus,
  TextInput,
} from 'src/design-system/components/TextInput/TextInput'
import { CrossedEyeSvg } from '../Icon/CrossedEye'
import { LockSvg } from '../Icon/Lock'
import { EyeSvg } from '../Icon/Eye'
import { StyleProp, ViewStyle } from 'react-native'
import { usePasswordPolicy } from 'components/Login/utils/usePasswordPolicy'

interface PasswordTextInputProps extends CustomTextInputProps {
  onValidation: (value: RuleStatus[] | null) => void
  onBlur: () => void
  textChange?: (text: string) => void
  disabled?: boolean
  skipValidation?: boolean
  label?: string
  containerStyle?: StyleProp<ViewStyle>
  ariaLabel?: string
  autoComplete?: 'current-password' | 'new-password'
}

export const PasswordTextInput: React.FC<PasswordTextInputProps> = ({
  onValidation,
  onBlur,
  textChange,
  label = 'Password',
  skipValidation = false,
  disabled,
  containerStyle,
  ariaLabel,
  autoComplete,
  ...rest
}) => {
  const [isSecuredText, setIsSecuredText] = useState(true)

  const passwordPolicy = usePasswordPolicy()

  return (
    <TextInput
      label={label}
      securedText={isSecuredText}
      keyboardType="default"
      iconLeft={<LockSvg />}
      iconRight={isSecuredText ? <EyeSvg /> : <CrossedEyeSvg />}
      iconRightPress={() => setIsSecuredText(isSecuredText => !isSecuredText)}
      disabled={disabled}
      validationPolicy={passwordPolicy}
      skipValidation={skipValidation}
      onBlur={onBlur}
      onValidation={onValidation}
      textChange={textChange}
      containerStyle={containerStyle}
      ariaLabel={ariaLabel}
      autoComplete={autoComplete}
      {...rest}
    />
  )
}
