import React, { useCallback, useState, useMemo, useId } from 'react'
import {
  View,
  StyleSheet,
  TextInput as RnTextInput,
  TextInputProps,
  ViewStyle,
  StyleProp,
} from 'react-native'
import { Colors } from 'src/design-system/theme'
import { Label } from '../Text/Label'
import { environment } from 'src/config'
import { PressableIcon } from '../Icon/PressableIcon'
import { noop } from 'lodash'

type KeyboardType = TextInputProps['keyboardType']

export type PolicyRule = {
  rule: (value: string) => boolean
  message: string
}

export type RuleStatus = {
  isValid: boolean
  message: string
}

export interface CustomTextInputProps extends TextInputProps {
  defaultValue?: string
  onChange?: () => void
  label?: string
  iconRight?: React.ReactElement
  iconLeft?: React.ReactElement
  iconRightPress?: () => void
  iconLeftPress?: () => void
  textChange?: (value: string) => void
  securedText?: boolean
  multiline?: boolean
  disabled?: boolean
  ariaLabel?: string
  ariaLabelBy?: string
  skipValidation?: boolean
  validationPolicy?: PolicyRule[]
  keyboardType?: KeyboardType
  onBlur?: () => void
  onValidation?: (value: RuleStatus[] | null) => void
  containerStyle?: StyleProp<ViewStyle>
  inputRef?: React.RefObject<RnTextInput>
}

const { isWeb } = environment

export const TextInput: React.FC<CustomTextInputProps> = React.memo(
  ({
    defaultValue,
    label,
    onChange,
    textChange = noop,
    iconLeft,
    iconRight,
    securedText = false,
    multiline = false,
    keyboardType = 'default',
    iconLeftPress,
    iconRightPress,
    disabled = false,
    skipValidation = false,
    validationPolicy,
    ariaLabel,
    ariaLabelBy,
    onBlur,
    onValidation,
    containerStyle,
    inputRef,
    ...rest
  }) => {
    const [isFocused, setIsFocused] = useState(false)
    const [isInvalid, setIsInvalid] = useState(false)
    const labelId = useId()

    const handleFocus = useCallback(() => {
      setIsFocused(true)
    }, [])

    // Validate the input text based on the validation policy
    const handleTextChange = useCallback(
      (value: string) => {
        textChange(value)
        if (validationPolicy) {
          // Return an array of rule validation status and error message
          const validationResults: RuleStatus[] = validationPolicy.map(
            rule => ({
              isValid: rule.rule(value),
              message: rule.message,
            }),
          )

          onValidation?.(validationResults)
          setIsInvalid(validationResults.some(result => !result.isValid))
        }
      },
      [onValidation, validationPolicy, setIsInvalid, textChange],
    )

    const focusedInputStyle = useMemo(() => {
      let borderColor = Colors.Borders.secondary
      const borderWidth = isFocused ? 2 : 1

      if (disabled) {
        borderColor = Colors.Interactive.disabledPrimary
      } else if (!skipValidation && !isFocused && isInvalid) {
        borderColor = Colors.Borders.negative
      } else if (isFocused) {
        borderColor = Colors.Borders.accent
      }

      return {
        borderColor,
        borderWidth,
      }
    }, [isInvalid, isFocused, disabled, skipValidation])

    const focusStyle = {
      paddingRight: iconRight ? 0 : 16,
      paddingLeft: iconLeft ? 0 : 16,
      ...(isWeb && {
        outline: 'none',
      }),
    }

    return (
      <View>
        {label ? (
          <Label
            nativeID={labelId}
            size={'S'}
            bold={true}
            style={styles.labelStyle}
          >
            {label}
          </Label>
        ) : null}

        <View
          style={[styles.inputContainer, focusedInputStyle, containerStyle]}
        >
          {iconLeft ? (
            <PressableIcon
              icon={iconLeft}
              onPress={iconLeftPress}
              disabled={disabled}
            />
          ) : null}

          <RnTextInput
            ref={inputRef}
            defaultValue={defaultValue}
            secureTextEntry={securedText}
            autoCapitalize="none"
            onFocus={handleFocus}
            onBlur={() => {
              setIsFocused(false)
              onBlur && onBlur()
            }}
            onChange={onChange}
            multiline={multiline}
            keyboardType={keyboardType}
            editable={!disabled}
            aria-label={ariaLabel}
            aria-labelledby={ariaLabelBy ?? labelId}
            onChangeText={handleTextChange}
            style={[styles.inputField, focusStyle]}
            {...rest}
          />

          {iconRight ? (
            <PressableIcon
              icon={iconRight}
              onPress={iconRightPress}
              disabled={disabled}
            />
          ) : null}
        </View>
      </View>
    )
  },
)

const styles = StyleSheet.create({
  labelStyle: { marginBottom: 8 },
  inputContainer: {
    height: 48,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 4,
  },
  inputField: {
    flex: 1,
    alignItems: 'center',
  },
})
