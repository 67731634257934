import React from 'react'
import {
  CustomTextInputProps,
  PolicyRule,
  RuleStatus,
  TextInput,
} from './TextInput'
import { EmailSvg } from '../Icon/Email'
import { useTranslation } from 'react-i18next'
import { StyleProp, ViewStyle } from 'react-native'

interface EmailTextInputProps extends CustomTextInputProps {
  defaultValue?: string
  disabled?: boolean
  onBlur?: () => void
  onValidation: (value: RuleStatus[] | null) => void
  textChange?: (text: string) => void
  containerStyle?: StyleProp<ViewStyle>
  ariaLabel?: string
  autoComplete?: 'username' | 'email' | 'off'
}

export const EmailTextInput: React.FC<EmailTextInputProps> = ({
  defaultValue,
  disabled,
  onBlur,
  onValidation,
  textChange,
  containerStyle,
  ariaLabel,
  autoComplete = 'off',
  ...rest
}) => {
  const { t } = useTranslation()

  const emailPolicy: PolicyRule[] = [
    {
      rule: email => /\S+@\S+\.\S+/.test(email),
      message: t('login:policy.email.invalidFormat'),
    },
    {
      rule: email => email.length > 0,
      message: t('login:policy.email.emptyField'),
    },
  ]

  return (
    <TextInput
      iconLeft={<EmailSvg size={16} />}
      inputMode="email"
      label={t('login:label.email')}
      defaultValue={defaultValue}
      validationPolicy={emailPolicy}
      disabled={disabled}
      onBlur={onBlur}
      onValidation={onValidation}
      textChange={textChange}
      containerStyle={containerStyle}
      ariaLabel={ariaLabel}
      autoComplete={autoComplete}
      {...rest}
    />
  )
}
