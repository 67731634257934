import React, { useState, useEffect, useMemo, useRef } from 'react'
import { View, StyleSheet, TextInput as RnTextInput } from 'react-native'
import { useTranslation } from 'react-i18next'
import { PasswordTextInput } from 'src/design-system/components/TextInput/PasswordTextInput'
import { RuleStatus } from 'src/design-system/components/TextInput/TextInput'
import { usePasswordPolicy } from '../utils/usePasswordPolicy'
import { InvalidStatus } from './InvalidStatus'
import { ValidStatus } from './ValidStatus'
import { DefaultStatus } from './DefaultStatus'

type Props = {
  hasPasswordTips?: boolean
  onVerifyPassword: (isMatch: boolean) => void
  newPassword: string
  setNewPassword: (value: string) => void
  onSubmitEditing?: () => void
  inputRef?: React.RefObject<RnTextInput>
}

export const PasswordInput: React.FC<Props> = ({
  hasPasswordTips = true,
  onVerifyPassword,
  newPassword,
  setNewPassword,
  onSubmitEditing,
  inputRef,
}) => {
  const { t } = useTranslation()
  const passwordPolicy = usePasswordPolicy()
  const [confirmedPW, setConfirmedPW] = useState<string>('')
  const [isPasswordMatch, setIsPasswordMatch] = useState<boolean | null>(null)
  const [policyMessages, setPolicyMessages] = useState<RuleStatus[] | null>(
    [] as RuleStatus[] | null,
  )

  // So we can focus on the next input
  const confirmPasswordRef = useRef<RnTextInput>(null)

  const passwordMatchIcon =
    isPasswordMatch === true ? (
      <ValidStatus status={t('login:policy.password:passwordMustMatch')} />
    ) : (
      <InvalidStatus status={t('login:policy.password:passwordMustMatch')} />
    )

  const policyMsgTip = useMemo(() => {
    return policyMessages?.map(policyMsg => (
      <View key={policyMsg.message} style={styles.tipStyle}>
        {policyMsg.isValid === true ? (
          <ValidStatus status={policyMsg.message} />
        ) : (
          <InvalidStatus status={policyMsg.message} />
        )}
      </View>
    ))
  }, [policyMessages])

  const PasswordMatchTip = () => (
    <View style={styles.tipStyle}>
      {isPasswordMatch === null ? (
        <DefaultStatus status={t('login:policy.password:passwordMustMatch')} />
      ) : (
        passwordMatchIcon
      )}
    </View>
  )

  useEffect(() => {
    if (newPassword === '' && confirmedPW === '') {
      return setIsPasswordMatch(null)
    }
    const isMatch =
      !!confirmedPW && !!newPassword && confirmedPW === newPassword
    const isValid = isMatch && !!policyMessages?.every(msg => msg.isValid)
    onVerifyPassword(isValid)
    setIsPasswordMatch(isMatch)
  }, [confirmedPW, newPassword, onVerifyPassword, policyMessages])

  const passwordPolicyDisplay = () =>
    !policyMessages?.length ? (
      <>
        {passwordPolicy?.map(msg => (
          <DefaultStatus key={msg.message} status={msg.message} />
        ))}
        <PasswordMatchTip />
      </>
    ) : (
      <>
        {policyMsgTip}
        <PasswordMatchTip />
      </>
    )

  return (
    <View style={{ gap: 8 }} testID="PasswordInputTestID">
      <View style={styles.passwordInputContainer}>
        <PasswordTextInput
          inputRef={inputRef}
          onValidation={value => setPolicyMessages(value ?? null)}
          onBlur={() => {}}
          textChange={value => setNewPassword(value)}
          autoComplete="new-password"
          onSubmitEditing={() => confirmPasswordRef.current?.focus()}
        />
        <PasswordTextInput
          inputRef={confirmPasswordRef}
          onValidation={() => {}}
          onBlur={() => {}}
          textChange={value => setConfirmedPW(value)}
          autoComplete="new-password"
          label={'Confirm password'}
          onSubmitEditing={onSubmitEditing}
        />
      </View>
      {hasPasswordTips ? passwordPolicyDisplay() : null}
    </View>
  )
}

const styles = StyleSheet.create({
  tipStyle: {
    flexDirection: 'row',
  },
  passwordInputContainer: {
    gap: 8,
  },
})
