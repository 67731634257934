import React, { PropsWithChildren, useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { Layout } from '../components/Layout'
import { useNavigation } from '@react-navigation/native'
import { Auth, AUTH_STATE, useAuth, userSessionService } from 'src/context/auth'
import { AuthHeader } from '../components/Header'
import { RuleStatus } from 'src/design-system/components/TextInput/TextInput'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useTranslation } from 'react-i18next'
import { EmailTextInput } from 'src/design-system/components/TextInput/EmailTextInput'
import { InvalidStatus } from '../components/InvalidStatus'
import { PasswordAction } from 'src/context/auth/utils/type'
import {
  AuthPrimaryButton,
  AuthSecondaryButton,
  IconBackButton,
} from '../components/Buttons'

export const ResetPassword: React.FC<PropsWithChildren> = () => {
  const { t } = useTranslation()
  const navigation = useNavigation()
  const { toggleAuthState } = useAuth()
  const [isCodeSent, setIsCodeSent] = useState(false)
  const [resendCounter, setResendCounter] = useState(60)
  const [email, setEmail] = useState('')
  const [isEmailDirty, setIsEmailDirty] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const [emailValidators, setEmailValidators] = useState<RuleStatus[] | null>(
    null,
  )
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const exAndSmallScreen = isExSmallScreen || isSmallScreen

  const allowFormSubmission = isFormValid && !isCodeSent && !isLoading

  const handleSendCode = async () => {
    const result = await Auth.forgetPassword(email)
    if (result?.errorCode) return
    toggleAuthState(AUTH_STATE.RESET_PASSWORD, true)
    setIsCodeSent(true)
    setResendCounter(60)
    userSessionService.setPwdActionInfo({
      email,
      action: PasswordAction.FORGET_PASSWORD,
      isPasswordExpired: false,
    })
    navigation.navigate(AUTH_STATE.CHANGE_PASSWORD.toLowerCase())
  }

  const handleSubmit = () => {
    if (allowFormSubmission) {
      setIsLoading(true)
      handleSendCode().then(() => setIsLoading(false))
    }
  }

  const handleBack = () => navigation.navigate(AUTH_STATE.LOGIN.toLowerCase())

  useEffect(() => {
    setIsFormValid(emailValidators?.every(email => email.isValid) ?? false)
  }, [email, emailValidators])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (isCodeSent) {
      timer = setInterval(() => {
        setResendCounter(prevCounter => {
          if (prevCounter === 1) {
            // When countdown finishes, reset resendCounter and enable resend button
            setIsCodeSent(false)
            clearInterval(timer)
            return 60
          }
          return prevCounter - 1
        })
      }, 1000)
    }

    return () => clearInterval(timer)
  }, [isCodeSent])

  return (
    <Layout>
      {exAndSmallScreen ? <IconBackButton onPress={handleBack} /> : null}
      <AuthHeader title={t('login:label.resetPassword')} />
      <EmailTextInput
        onValidation={setEmailValidators}
        onBlur={() => setIsEmailDirty(true)}
        textChange={setEmail}
        ariaLabel="EmailField"
        returnKeyType="send"
        onSubmitEditing={handleSubmit}
      />
      <View style={styles.invalidEmailMsg}>
        {emailValidators?.map(email =>
          !email.isValid && isEmailDirty ? (
            <InvalidStatus key={email.message} status={email.message} />
          ) : null,
        )}
      </View>
      <View style={[styles.btnContainer]}>
        <AuthPrimaryButton
          onPress={handleSubmit}
          title={
            !isCodeSent
              ? t('login:label.sendCode')
              : t('login:label.resendCode', {
                  seconds: `${resendCounter} second${
                    resendCounter > 1 ? 's' : ''
                  }`,
                })
          }
          disabled={!allowFormSubmission}
          loading={isLoading}
        />
        {!exAndSmallScreen ? (
          <AuthSecondaryButton
            onPress={handleBack}
            title={t('login:pin.backToSign')}
          />
        ) : null}
      </View>
    </Layout>
  )
}

const styles = StyleSheet.create({
  invalidEmailMsg: {
    paddingVertical: 8,
    gap: 8,
  },
  btnContainer: {
    borderRadius: 4,
    height: 48,
    marginTop: 36,
    gap: 16,
  },
})
