import vetradarmobile from 'src/vetradar-exports'
import { ErrorCode, FetchOptions } from './type'
import { toast, DEFAULT_DURATION } from 'components/common'
import { userSessionService } from './userSession'
import { Auth } from './auth'
import { reloadApp } from 'src/utils/reloadApp'

const { authEndpoint } = vetradarmobile
export class AuthAPI {
  private static endpoint = `http${
    authEndpoint.indexOf('localhost') !== -1 ? '' : 's'
  }://${authEndpoint}`

  private static delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  static get(url: string, options: FetchOptions = {}, retries = 3) {
    return AuthAPI.client(
      url,
      {
        ...options,
        method: 'GET',
      },
      retries,
    )
  }

  static post(url: string, options: FetchOptions = {}, retries = 3) {
    return AuthAPI.client(
      url,
      {
        ...options,
        method: 'POST',
      },
      retries,
    )
  }

  private static async client(
    url: string,
    options: FetchOptions,
    retries = 3,
    retryDelay = 500,
  ): Promise<any> {
    try {
      const session = userSessionService.getUserSession()
      const headers: HeadersInit = {
        'Content-Type': 'application/json',
        ...options.headers,
      }

      // default token
      if (session?.authenticationResult?.accessToken) {
        headers.Authorization = `Bearer ${session?.authenticationResult?.accessToken}`
      }

      // Override default token if token given
      if (options?.headers?.token) {
        headers.Authorization = `Bearer ${options.headers?.token}`
      }

      const response = await fetch(`${this.endpoint}${url}`, {
        ...options,
        headers,
        body: options.payload ? JSON.stringify(options.payload) : undefined,
      })

      const contentLength = response?.headers?.get('content-length')
      if (contentLength === '0') return
      const result = await response.json()
      if (result?.error) {
        if (
          [ErrorCode.ACCESS_TOKEN_ERROR, ErrorCode.UNAUTHORIZED_ERROR].includes(
            result.error?.errorCode,
          ) &&
          retries > 0
        ) {
          const newRetry = retries - 1
          const newDelay = retryDelay * 2
          if (newRetry === 0) {
            const code = result.errorCode ?? ErrorCode.REFRESH_TOKEN_ERROR
            await Auth.signOut() // when refresh token expired, sign out the user and reload the app
            userSessionService.setErrorCode(code)
            return reloadApp()
          }
          await Auth.refreshToken(undefined, 0)
          await this.delay(newDelay)
          return await this.client(url, options, newRetry, newDelay) // Retry the request
        }
        result.error.errorCode =
          result.error?.errorCode ?? ErrorCode.INTERNAL_SERVER_ERROR
        return AuthAPI.postError(result?.error)
      }
      return result
    } catch (e) {
      return AuthAPI.postError({ errorCode: ErrorCode.INTERNAL_SERVER_ERROR })
    }
  }

  static postError(error: { errorCode: ErrorCode; message?: string }) {
    switch (error.errorCode) {
      case ErrorCode.USER_CONFLICT_WITH_LOCAL_ORG:
        toast.error(
          'Unable to access the organisation. Previously saved organisation was cleared, please sign in again.',
        )
        break
      case ErrorCode.REQUEST_BODY_ERROR:
        if (error.message)
          toast.error(error.message, undefined, undefined, DEFAULT_DURATION)
        break
      case ErrorCode.UNAUTHORIZED_ERROR:
      case ErrorCode.ACCESS_TOKEN_ERROR:
      case ErrorCode.REFRESH_TOKEN_ERROR:
      case ErrorCode.TEMP_PASSWORD_EXPIRED_SESSION:
        toast.error(
          'Vet Radar automatically locked after a specified period. Please sign in again.',
        )
        break
      case ErrorCode.USER_NOT_FOUND:
      case ErrorCode.USER_LINKING_ERROR:
        toast.error(
          'You cannot sign in because of a problem with your user account. Speak to your practice manager.',
          undefined,
          undefined,
          DEFAULT_DURATION,
        )
        break
      case ErrorCode.USER_DISABLED:
        toast.error(
          'You cannot sign in because your user account is disabled. Speak to your practice manager.',
          undefined,
          undefined,
          DEFAULT_DURATION,
        )
        break
      case ErrorCode.USER_EMAIL_ALREADY_VERIFIED:
        toast.error(
          'You entered the verification code before.',
          undefined,
          undefined,
          DEFAULT_DURATION,
        )
        break
      case ErrorCode.PIN_NOT_FOUND:
        toast.error(
          'Your PIN is disabled. To set a new PIN, first sign in with your email address and password.',
          undefined,
          undefined,
          DEFAULT_DURATION,
        )
        break
      case ErrorCode.PIN_EXPIRED:
        toast.error(
          'Your PIN is expired. To set a new PIN, first sign in with your email address and password.',
          undefined,
          undefined,
          DEFAULT_DURATION,
        )
        break
      case ErrorCode.PIN_MAX_ATTEMPT:
        toast.error(
          'You entered an incorrect PIN more than 3 times.',
          undefined,
          undefined,
          DEFAULT_DURATION,
        )
        break
      case ErrorCode.PIN_INCORRECT:
        toast.error(
          'The PIN is incorrect.',
          undefined,
          undefined,
          DEFAULT_DURATION,
        )
        break
      case ErrorCode.PIN_SAME_NUMBERS_ERROR:
        toast.error(
          'A PIN cannot have the same digit five times.',
          undefined,
          undefined,
          DEFAULT_DURATION,
        )
        break
      case ErrorCode.PIN_SEQUENTIAL_NUMBERS_ERROR:
        toast.error(
          'A PIN cannot have five digits that follow in a sequence.',
          undefined,
          undefined,
          DEFAULT_DURATION,
        )
        break
      case ErrorCode.SETUP_PIN_EXPIRED_PASSWORD:
        toast.error(
          'Your password is expired. To set a PIN, first change your password.',
          undefined,
          undefined,
          DEFAULT_DURATION,
        )
        break
      case ErrorCode.PASSWORD_REQUIREMENT_ERROR:
        toast.error(
          'The password is not strong enough. Enter a stronger password.',
          undefined,
          undefined,
          DEFAULT_DURATION,
        )
        break
      case ErrorCode.CURRENT_PASSWORD_INCORRECT: // For change password in session
        toast.error(
          'The password in the Current password box is incorrect.',
          undefined,
          undefined,
          DEFAULT_DURATION,
        )
        break
      case ErrorCode.PASSWORD_OR_EMAIL_INCORRECT:
        toast.error(
          'The email address or password is incorrect.',
          undefined,
          undefined,
          DEFAULT_DURATION,
        )
        break
      case ErrorCode.PASSWORD_OR_EMAIL_MAX_ATTEMPT:
        // Don't display error message for this error code. Reference: https://ezyvet.atlassian.net/browse/VR-10811
        // toast.error(
        //   'You tried to sign in more than 10 times. Wait 30 minutes or speak to your practice manager.',
        //   undefined,
        //   undefined,
        //   10000, // 10 seconds
        // )
        break
      case ErrorCode.CHANGE_PASSWORD_IN_SESSION_MAX_ATTEMPT:
        toast.error(
          'You entered an incorrect password more than 3 times. Please sign in again.',
          undefined,
          undefined,
          DEFAULT_DURATION,
        )
        break
      case ErrorCode.VERIFICATION_CODE_INCORRECT:
        toast.error(
          'The verification code is incorrect.',
          undefined,
          undefined,
          DEFAULT_DURATION,
        )
        break
      case ErrorCode.VERIFICATION_CODE_EXPIRED:
        toast.error(
          'The verification code is expired. To get a new code, select Send code.',
          undefined,
          undefined,
          DEFAULT_DURATION,
        )
        break
      case ErrorCode.INTERNAL_SERVER_ERROR:
      default:
        toast.error(
          'Something went wrong. Please try again shortly, or speak to your practice manager if issue persists.',
          undefined,
          undefined,
          DEFAULT_DURATION,
        )
    }
    return error
  }
}
